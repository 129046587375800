<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">비밀번호 찾기<br/><span>비밀번호를 분실 하셨나요?</span></div>
				</div>
				<div class="contents-body">
					<div class="contents-body-box pw">
						<div class="guide-text">
							<span>아이디를 조회 해 주세요!</span><br/>
						</div>
						<div class="mem-id-input">
							<input type="text" name id="txt_id" placeholder="아이디 입력" v-model="mberId">
						</div>
						<div class="confirm-btn" @click="chkId()">
							아이디 조회
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberId : ''
		}
	},

	methods : {
		chkId() {
			if(!this.mberId) {
				alert('아이디를 입력하세요.');
				return false;
			}

			this.$.httpPost('api/mem/mberIdChk', {mberId : this.mberId}, '')
				.then(res => {
					//console.log('RESULT :', res);

					if(res.data.resultCode == '000') {
						if(res.data.idChkYn == 'Y') {
							this.$router.push({name:'MEM002M05', params:{mberId : this.mberId}});
						}else {
							alert('아이디가 존재하지 않습니다.');
						}
					}
				})
		}
	}
}
</script>